/* Base Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Header Styles */
.header {
  position: sticky;
  top: 0;
  z-index: 50;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo-icon {
  position: relative;
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.logo-icon::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
}

.chess-piece {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chess-piece::after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: white;
  background-image: url("https://coolchess.ru/school.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-text {
  font-size: 24px;
  font-weight: normal;
}

.desktop-nav {
  display: none;
}

@media (min-width: 768px) {
  .desktop-nav {
    display: flex;
    align-items: center;
    gap: 32px;
  }
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #5d4ef5;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.whatsapp-button {
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #25d366;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .whatsapp-button {
    display: flex;
  }
}

.signup-button {
  display: none;
  height: 40px;
  padding: 0 32px;
  border-radius: 4px;
  background-color: #4f46e5;
  color: white;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.signup-button:hover {
  background-color: #4338ca;
}

@media (min-width: 768px) {
  .signup-button {
    display: inline-flex;
  }
}

.mobile-menu-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .mobile-menu-button {
    display: none;
  }
}

.mobile-nav {
  display: block;
  background-color: white;
  border-top: 1px solid #f1f1f1;
  padding: 16px 0;
}

.mobile-nav-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mobile-actions {
  display: flex;
  gap: 16px;
  margin-top: 8px;
}

/* Hero Section */
.hero-section {
  padding: 48px 0 64px;
  position: relative;
  overflow: hidden;
}

.hero-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  align-items: center;
}

@media (min-width: 992px) {
  .hero-container {
    grid-template-columns: 1fr 1fr;
  }
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.free-lesson-badge {
  padding: 8px 20px;
  background-color: #4f46e5;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-radius: 20px;
  width: 260px;
}

.hero-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .hero-title {
    font-size: 48px;
  }
}

@media (min-width: 992px) {
  .hero-title {
    font-size: 56px;
  }
}

.hero-subtitle {
  font-size: 18px;
  color: #555;
}

@media (min-width: 768px) {
  .hero-subtitle {
    font-size: 20px;
  }
}

.start-button {
  display: inline-flex;
  height: 48px;
  padding: 0 32px;
  border-radius: 4px;
  background-color: #ecdf20;
  color: black;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.start-button:hover {
  background-color: #d4c81c;
}

.hero-image {
  position: relative;
}

.shape-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.shape {
  border-radius: 50%;
}

.purple-pill {
  height: 160px;
  width: 100%;
  background-color: #b8b2ff;
}

.pink-circle {
  height: 96px;
  width: 96px;
  background-color: #ff9fb1;
  margin-left: auto;
}

.yellow-pill {
  height: 192px;
  width: 100%;
  background-color: #ecdf20;
  margin-top: 48px;
}

.green-pill {
  height: 128px;
  width: 100%;
  background-color: #7ed957;
}

.chess-player {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chess-player img {
  height: 300px;
  width: 300px;
  object-fit: cover;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .chess-player img {
    height: 400px;
    width: 400px;
  }
}

.hero-location {
  margin-top: 16px;
  font-size: 16px;
  color: #555;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

@media (min-width: 768px) {
  .hero-location {
    justify-content: flex-start;
  }
}

/* Features Section */
.features-section {
  padding: 64px 0 96px;
}

.section-header {
  text-align: center;
  margin-bottom: 48px;
}

.section-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .section-title {
    font-size: 36px;
  }
}

.section-subtitle {
  font-size: 18px;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
}

.features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .features-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.feature-card {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
  transition: box-shadow 0.2s;
}

.feature-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.feature-icon.purple {
  background-color: #b8b2ff;
}

.feature-icon.yellow {
  background-color: #ecdf20;
}

.feature-icon.green {
  background-color: #7ed957;
}

.feature-icon.pink {
  background-color: #ff9fb1;
}

.feature-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.feature-description {
  color: #555;
}

/* Programs Section */
.programs-section {
  padding: 64px 0 96px;
  background-color: #f9f9f9;
}

.programs-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 768px) {
  .programs-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .programs-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.program-card {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
}

.program-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.program-image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program-image.purple {
  background-color: #b8b2ff;
}

.program-image.yellow {
  background-color: #ecdf20;
}

.program-image.green {
  background-color: #7ed957;
}

.program-image img {
  object-fit: cover;
}

.program-content {
  padding: 24px;
}

.program-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.program-description {
  color: #555;
  margin-bottom: 16px;
}

.program-features {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.program-feature {
  display: flex;
  align-items: center;
}

.check-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #4f46e5;
}

.program-button {
  display: inline-flex;
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: #4f46e5;
  color: white;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.program-button:hover {
  background-color: #4338ca;
}

/* Testimonials Section */
.testimonials-section {
  padding: 64px 0 96px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
}

@media (min-width: 768px) {
  .testimonials-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .testimonials-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.testimonial-card {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
  transition: box-shadow 0.2s;
}

.testimonial-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.testimonial-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
}

.testimonial-avatar.purple {
  background-color: #b8b2ff;
}

.testimonial-avatar.yellow {
  background-color: #ecdf20;
}

.testimonial-avatar.green {
  background-color: #7ed957;
}

.author-name {
  font-weight: 700;
}

.author-info {
  font-size: 14px;
  color: #777;
}

.testimonial-text {
  color: #555;
}

/* CTA Section */
.cta-section {
  padding: 64px 0 96px;
  background-color: #4f46e5;
  color: white;
}

.cta-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  align-items: center;
}

@media (min-width: 992px) {
  .cta-container {
    grid-template-columns: 1fr 1fr;
  }
}

.cta-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.cta-title {
  font-size: 32px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .cta-title {
    font-size: 36px;
  }
}

.cta-subtitle {
  font-size: 18px;
  opacity: 0.9;
}

.cta-benefits {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.cta-benefit {
  display: flex;
  align-items: center;
}

.benefit-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #ecdf20;
}

.signup-form-container {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-input {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
}

.form-submit {
  height: 48px;
  border-radius: 4px;
  background-color: #ecdf20;
  color: black;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-submit:hover {
  background-color: #d4c81c;
}

/* FAQ Section */
.faq-section {
  padding: 64px 0 96px;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.faq-item {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
}

.faq-question {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}

.faq-answer {
  color: #555;
}

/* Promotions Section */
.promotions-section {
  padding: 64px 0;
  background-color: #f9f9f9;
}

.promotions-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

@media (min-width: 768px) {
  .promotions-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .promotions-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.promotion-card {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.promotion-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.promotion-icon {
  font-size: 32px;
  margin-bottom: 16px;
}

.promotion-title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.promotion-description {
  color: #555;
  font-size: 14px;
}

/* Pricing Section */
.pricing-section {
  padding: 64px 0;
  background-color: #f9f9f9;
}

.pricing-card {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
}

.pricing-header {
  background-color: #5d4ef5;
  color: white;
  padding: 32px;
  text-align: center;
}

.pricing-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.pricing-amount {
  font-size: 36px;
  font-weight: 700;
}

.pricing-period {
  font-size: 16px;
  opacity: 0.8;
}

.pricing-features {
  padding: 32px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

@media (min-width: 768px) {
  .pricing-features {
    grid-template-columns: 1fr 1fr;
  }
}

.pricing-feature {
  display: flex;
  align-items: center;
}

.pricing-feature .check-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  color: #5d4ef5;
}

.pricing-cta {
  padding: 0 32px 32px;
  text-align: center;
}

.pricing-trial {
  font-weight: 700;
  margin-bottom: 8px;
  color: #5d4ef5;
}

.pricing-limited {
  margin-bottom: 16px;
  color: #e11d48;
  font-weight: 500;
}

.pricing-button {
  display: inline-flex;
  height: 48px;
  padding: 0 32px;
  border-radius: 4px;
  background-color: #ecdf20;
  color: black;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.pricing-button:hover {
  background-color: #d4c81c;
}

/* Improved form styles */
.signup-form-container {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #333;
}

.form-subtitle {
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
}

.form-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-input {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-input:focus {
  outline: none;
  border-color: #5d4ef5;
  box-shadow: 0 0 0 2px rgba(93, 78, 245, 0.2);
  background-color: white;
}

.messenger-section {
  margin-bottom: 24px;
}

.messenger-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
}

.messenger-options {
  display: flex;
  gap: 16px;
}

.messenger-option {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.2s;
}

.messenger-option:hover {
  border-color: #5d4ef5;
}

.messenger-option.selected {
  border-color: #5d4ef5;
  background-color: rgba(93, 78, 245, 0.05);
}

.messenger-icon {
  width: 24px;
  height: 24px;
}

.messenger-icon img {
  width: 100%;
  height: 100%;
}

.messenger-name {
  font-size: 14px;
  font-weight: 500;
  color: #555;
}

.privacy-policy {
  margin-bottom: 24px;
}

.privacy-label {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  color: #555;
}

.privacy-label input {
  margin-top: 3px;
}

.privacy-label a {
  color: #5d4ef5;
  text-decoration: underline;
}

.form-submit {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #ecdf20;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.form-submit:hover:not(.disabled) {
  background-color: #d4c81c;
}

.form-submit.disabled {
  background-color: #f1f1f1;
  color: #999;
  cursor: not-allowed;
}

.form-success {
  text-align: center;
  padding: 32px 16px;
}

.success-icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #4caf50;
  color: white;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 24px;
}

.success-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #333;
}

.success-message {
  font-size: 16px;
  color: #555;
}

/* Improved responsive styles */
@media (max-width: 768px) {
  .cta-container {
    grid-template-columns: 1fr;
  }

  .hero-title {
    font-size: 32px;
  }

  .section-title {
    font-size: 28px;
  }

  .messenger-options {
    flex-direction: column;
  }
}

/* Improved color scheme */
.header,
.footer {
  background-color: #fff;
}

.hero-section {
  background-color: #f9f9f9;
}

.cta-section {
  background-color: #5d4ef5;
}

.feature-icon.purple,
.program-image.purple,
.testimonial-avatar.purple {
  background-color: #b8b2ff;
}

.feature-icon.yellow,
.program-image.yellow,
.testimonial-avatar.yellow {
  background-color: #ecdf20;
}

.feature-icon.green,
.program-image.green,
.testimonial-avatar.green {
  background-color: #7ed957;
}

.feature-icon.pink,
.testimonial-avatar.pink {
  background-color: #ff9fb1;
}

.start-button,
.pricing-button,
.form-submit {
  background-color: #ecdf20;
  color: #000;
}

.start-button:hover,
.pricing-button:hover,
.form-submit:hover:not(.disabled) {
  background-color: #d4c81c;
}

.signup-button,
.program-button {
  background-color: #5d4ef5;
}

.signup-button:hover,
.program-button:hover {
  background-color: #4d3ed5;
}

/* CTA Section */
.cta-section {
  padding: 64px 0 96px;
  background-color: #4f46e5;
  color: white;
}

.cta-section .pricing-trial,
.cta-section .pricing-limited {
  color: white;
}
